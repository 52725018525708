// extracted by mini-css-extract-plugin
export var diversityCover = "p_gK";
export var diversityTitle = "p_gL";
export var CareerDiversity__scroll = "p_gM";
export var about = "p_gz";
export var diversityImg = "p_gN";
export var whitebox = "p_fG";
export var supportImg = "p_gP";
export var supportContainer = "p_gQ";
export var marginWrap = "p_gR";
export var performanceTitle = "p_gS";
export var performanceContainer = "p_gT";
export var faq = "p_gV";
export var faqTab = "p_gW";
export var tab1 = "p_gX";
export var recruitment = "p_gY";
export var arrow = "p_fP";
export var triangle = "p_fQ";
export var entry = "p_gZ";
export var contactBtn = "p_g0";
export var viewAll = "p_gy";
export var linkMargin = "p_g1";
export var linkMarginTwo = "p_g2";
export var acd = "p_g3";
export var answer = "p_g4";
export var fadein = "p_g5";
export var drawer = "p_g6";