import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import * as styles from "./SpHorizontalMenu.module.scss";
import classNames from "classnames";

type SpHorizontalMenuProps = {
  children: ReactNode[];
  className?: string;
};

export const SpHorizontalMenu: FunctionComponent<SpHorizontalMenuProps> = ({
  children = [],
  className = "",
}) =>
  children ? (
    <div className={classNames(styles.container, className)}>
      <div className={styles.inner}>
        {children.map((child, i) => (
          <div key={`sp-menu-${i}`}>{child}</div>
        ))}
      </div>
    </div>
  ) : null;
