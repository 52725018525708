import React from "react";
import {AnchorHeaders} from "./AnchorHeaders";
import * as styles from './menus.module.scss';

const links = [
    {
        id: "Description",
        label: "募集職種一覧",
    },
    {
        id: "Ideal",
        label: "求める人物像",
    },
    {
        id: "Flow",
        label: "応募・選考フロー",
    },
    {
        id: "Access",
        label: "アクセス",
    },
    {
        id: "Faq",
        label: "FAQ",
    },
];



export const MidOpportunitiesHeader = () => <AnchorHeaders links={ links } spContainerClassName={styles.midAnchorSpMenu} />