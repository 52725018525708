import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../../../styles/careers/midinfo.module.scss";
import { CareerMidLayout } from "../../../organisms/careers/CareerMidLayout";
import { Link } from "gatsby";
import { MidOpportunitiesHeader } from "../../../organisms/menus/MidOpportunitiesHeader";
import Scroll, { scrollImageColorTypes } from "../../../atoms/newScroll";
import { LinkMargin } from "../../../atoms/LinkMargin";
import { LinkMargin2 } from "../../../atoms/LinkMargin2";
import { SEO } from "../../../atoms/seo/SEO";
import seoImage from "../../../images/career_mid/career_mid_head.jpg";

export default ({ location: { pathname } }) => (
  <CareerMidLayout
    current="opportunities"
    extraHeaders={<MidOpportunitiesHeader />}
  >
    <SEO
      pathname={pathname}
      title="中途採用TOP | MidCareerTop"
      description="スプリーブホールディングス株式会社のキャリア採用情報はこちらからご確認いただけます。社会、顧客、従業員に対して、「この上ない最高」を提供する会社を共に目指し、人と人の繋がりを強めて力強く事業を推進していく仲間を募集しています。"
      image={seoImage}
    />
    <div className={styles.mid}>
      <h2>Career Opportunities</h2>
      <h3>採用情報</h3>
      <Scroll type={scrollImageColorTypes.BLACK} />
    </div>

    <a id="Description" />
    <LinkMargin />
    <div className={styles.joblistTitle}>
      <h2>
        Job Description<span>募集職種一覧</span>
      </h2>
    </div>

    <div className={styles.joblist}>
      <h2>現在募集している職種はございません。</h2>
    </div>

    <a id="Ideal" />
    <LinkMargin2 />
    <div className={styles.idealGray}>
      <div className={styles.idealTitle}>
        <h2>
          Ideal Candidate Profile<span>求める人物像</span>
        </h2>
      </div>
    </div>

    <div className={styles.idealContainer}>
      <div className={styles.idealText}>
        <h2>
          仲間と協調し、
          <br />
          変化し続けることができる人
        </h2>
        <h3>「この上ない最高の世界を創る」</h3>
        <p>
          ビジョンは１つでも、そこへ繋がる道はいくらでもあります。
          <br />
          世界の状況に合わせ、柔軟に形や方法を変化させながら、共に向かえる仲間を募集します。
        </p>
      </div>
      <StaticImage
        className={styles.idealImg}
        src="../../../images/career_mid_opportunities/_kpo1226_re.jpg"
        alt=""
        aspectRatio={16 / 9}
      />
    </div>

    <div className={styles.idealWhite}>
      <h2>人事からのメッセージ</h2>
      <h3>”最高”ってなんだろう？と問い続けよう。</h3>
      <p>
        世界にとって、お客様にとって、一緒に働く仲間にとって、そして自分自身にとって、最高って何だろう？
        <br />
        どうすれば近づけるだろう？
        <br />
        ひとつひとつの進化を皆で喜びながら、常に自分の武器を磨き続けていきたい。
        <br />
        想像を越える感動を世界に、お客様に、仲間に味わい続けてもらうために、
        <br />
        私たちは、いつまでも満足せずに、高め続けられる組織で在りたい。
      </p>
    </div>

    <a id="Flow" />
    <LinkMargin2 />
    <div className={styles.flow}>
      <h2>
        Selection Flow<span>応募・選考フロー</span>
      </h2>
      <p>
        まずは<Link to="/careers/mid/entry">エントリー</Link>をお願いします。
        <br />
        エントリーいただいた方には会社説明会・選考情報などをご案内させていただきます。
        <br />
        ※応募先によってい選考フローが変わります。
      </p>
      <div className={styles.flowContainer}>
        <div className={styles.flowBoxOne}>
          <h2>Step1</h2>
          <h4> </h4>
          <h3>プレエントリー</h3>
        </div>

        <div className={styles.triangle}> </div>

        <div className={styles.flowBoxOne}>
          <h2>Step2</h2>
          <h3>書類選考</h3>
        </div>

        <div className={styles.triangle}> </div>

        <div className={styles.flowBoxOne}>
          <h2>Step3</h2>
          <h3>複数面接</h3>
        </div>
      </div>
    </div>

    <a id="Access" />
    <LinkMargin2 />
    <div className={styles.access}>
      <h2>
        Access<span>面接場所</span>
      </h2>
      <p>
        まずはプレエントリーをお願いします。
        <br />
        プレエントリーいただいた方には会社説明会・選考情報などをご案内させていただきます。
      </p>
      <div className={styles.accessContaniner}>
        <div className={styles.accessContaninerLeft}>
          <p>大阪本社</p>
        </div>
        <div className={styles.accessContaninerRight}>
          <p>
            〒530-0011 大阪府大阪市北区大深町3番1号 グランフロント大阪 北館6階
            <br />
            TEL:06-7639-1480
          </p>
        </div>
      </div>
      <div className={styles.accessContaniner}>
        <div className={styles.accessContaninerLeft}>
          <p>東京本社</p>
        </div>
        <div className={styles.accessContaninerRight}>
          <p>
            〒107-0062 東京都港区南青山1丁目12-3 LIFORK MINAMI AOYAMA S215
            <br />
            TEL:03-6706-2861
          </p>
        </div>
      </div>
      <div className={styles.accessContaniner}>
        <div className={styles.accessContaninerLeft}>
          <p>福岡支社</p>
        </div>
        <div className={styles.accessContaninerRight}>
          <p>
            〒810-0001 福岡県福岡市中央区天神1丁目1-1 fabbit Global Gateway
            ACROS Fukuoka
          </p>
        </div>
      </div>
      <div className={styles.accessContaniner}>
        <div className={styles.accessContaninerLeft}>
          <p>名古屋支社</p>
        </div>
        <div className={styles.accessContaninerRight}>
          <p>〒450-6321 愛知県名古屋市中村区名駅1-1-1 JPタワー名古屋21階</p>
        </div>
      </div>
      <div className={styles.accessContaniner}>
        <p>
          詳しくは
          <Link to="/careers/mid/why-suprieve/#workplace"> 働く場所</Link>{" "}
          をご覧ください。
        </p>
      </div>
      {/*<div className={styles.button}>*/}
      {/*  <Link to='/contact'>*/}
      {/*    <a className={styles.btnChange}>*/}
      {/*      <span>Contact</span><span>View All</span></a>*/}
      {/*  </Link>*/}
      {/*</div>*/}
    </div>

    <a id="Faq" />
    <LinkMargin2 />
    <div className={styles.faq}>
      <h2>
        FAQ<span>よくある質問</span>
      </h2>
      <p>皆さんから多く寄せられたご質問について、回答を掲載しています。</p>
      <div className={styles.faqTab}>
        <Link to="#oubo">
          <h5>応募について</h5>
        </Link>
        <Link to="#saiyou">
          <h5>採用・選考について</h5>
        </Link>
        <Link to="#shigoto">
          <h5>仕事について</h5>
        </Link>
        <Link to="#sonota">
          <h5>その他</h5>
        </Link>
      </div>
    </div>

    <div className={styles.acdBackground}>
      <div className={styles.acd}>
        <a id="oubo" />
        <LinkMargin2 />
        <h2>応募について</h2>
        <details>
          <summary className={styles.question}>
            Q.当WEBサイトにも掲載されている職種以外の募集はありますか？
          </summary>
          <div className={styles.answer}>
            <p>
              A.場合によって他にも募集を行うこともございますが、基本的には当サイトの通りです。
            </p>
          </div>
        </details>
        <details>
          <summary className={styles.question}>
            Q.過去に選考で不合格になったことがあるのですが、再応募は可能ですか？
          </summary>
          <div className={styles.answer}>
            <p>
              A.時々の状況によっても対応が異なりますため、一度お問い合わせよりご連絡いただけますと幸いです。
            </p>
          </div>
        </details>
        <details>
          <summary className={styles.question}>
            Q.第二新卒でも応募は可能ですか？
          </summary>
          <div className={styles.answer}>
            <p>A.可能です。随時受付をさせていただいております。</p>
          </div>
        </details>
      </div>

      <div className={styles.acd}>
        <a id="saiyou" />
        <LinkMargin2 />
        <h2>採用・選考について</h2>
        <details>
          <summary className={styles.question}>
            Q.インターネットの知識や技術的なスキル、資格は必要ですか？
          </summary>
          <div className={styles.answer}>
            <p>
              A.入社後にしっかりと身に付けていただきますため、特に必須の知識、スキル、資格はございません。
              <br />
              急成長を続けており新規事業を頻繁に生まれる状況のため、今お持ちのスキル、また今後取得予定の資格等ございましたら今後に活きる可能性があります。
            </p>
          </div>
        </details>
        <details>
          <summary className={styles.question}>
            Q.筆記テストはありますか？
          </summary>
          <div className={styles.answer}>
            <p>A.特にございません。</p>
          </div>
        </details>
        <details>
          <summary className={styles.question}>
            Q.リモートでの面接は可能ですか？
          </summary>
          <div className={styles.answer}>
            <p>
              A.はい、可能です。2020年3月より全面的にオンライン面接に切り替えております。
            </p>
          </div>
        </details>
        <details>
          <summary className={styles.question}>
            Q.18時以降、もしくは土日祝の面接は可能ですか。
          </summary>
          <div className={styles.answer}>
            <p>
              A.場合によっては18時以降、土日祝の面接も可能です。
              <br />
              是非一度ご相談ください。
            </p>
          </div>
        </details>
      </div>

      <div className={styles.acd}>
        <a id="shigoto" />
        <LinkMargin2 />
        <h2>仕事について</h2>
        <details>
          <summary className={styles.question}>
            Q.配置はどのようにして決まりますか？
          </summary>
          <div className={styles.answer}>
            <p>
              A.クライアント様のご状況、お住まいの場所で決まります。
              <br />
              基本的にご自宅から1時間半圏内での配属となることが多いです。
            </p>
          </div>
        </details>
        <details>
          <summary className={styles.question}>
            Q.行きたい部署の希望は出せますか。
          </summary>
          <div className={styles.answer}>
            <p>
              A.ご希望をお伝えいただくことはもちろん可能ですが、配属先は基本的には募集している部署での配属となります。
              <br />
              また入社後、異動の可能性もございます。
            </p>
          </div>
        </details>
        <details>
          <summary className={styles.question}>
            Q.配属後の異動について教えてください。
          </summary>
          <div className={styles.answer}>
            <p>
              A.HR部門では、クライアント様やご自身のご家庭のご状況などにより異動の可能性はございますが、確率は高くはございません。
              <br />
              また、キャリアアップをされる中で例えば1年後、2年後に他部門、他事業部への推薦があった際に異動になることがあります。
            </p>
          </div>
        </details>
        <details>
          <summary className={styles.question}>
            Q.試用期間はありますか？
          </summary>
          <div className={styles.answer}>
            <p>A.入社してから6ヵ月間が試用期間となります。</p>
          </div>
        </details>
      </div>

      <div className={styles.acd}>
        <a id="sonota" />
        <LinkMargin2 />
        <h2>その他</h2>
        <details>
          <summary className={styles.question}>
            Q.社員寮／家賃補助はありますか？
          </summary>
          <div className={styles.answer}>
            <p>
              A.社員寮、家賃補助ともに現時点ではございません。
              <br />
              転居を伴う転勤はございませんが、お引越しをされる際のサポートがございます。
            </p>
          </div>
        </details>
        <details>
          <summary className={styles.question}>
            Q.部署間を越えた社員同士の交流はありますか？
          </summary>
          <div className={styles.answer}>
            <p>
              A.現在は新型コロナウィルス感染拡大防止のため実施を中断しておりますが、様々なイベントを開催しております。
              <br />
              運動会、ハロウィンパーティー、クリスマスパーティー、カラオケ大会、新年度、忘年会etc...
            </p>
          </div>
        </details>
      </div>

      <p>
        その他のご質問に関してはコンタクトページよりメールにてお問い合わせください。
      </p>

      <div className={styles.button}>
        <div className={styles.viewAll}>
          <Link to="/contact?type=careers">Contact</Link>
        </div>
      </div>
    </div>
  </CareerMidLayout>
);
