import React, {
  createRef,
  CSSProperties,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as styles from "./menus.module.scss";
import { Link } from "gatsby";
import {
  AnchorContext,
  AnchorProvider,
} from "../../atoms/anchor/AnchorProvider";
import { cssWithPrefix } from "../../utils/prefixer";
import { SpHorizontalMenu } from "../../atoms/menu/SpHorizontalMenu";

interface AnchorLink {
  readonly id: string;
  readonly label: string;
}

interface AnchorHeadersProps {
  links: AnchorLink[];
  spContainerClassName?: string;
}

export const AnchorHeaders = ({
  links,
  spContainerClassName = styles.anchorSpMenu,
}: AnchorHeadersProps) => {
  const [currentStyle, setCurrentStyle] = useState({});
  const { setAnchors, currentAnchorIndex } = useContext(AnchorContext);
  const menuParentRef = createRef<HTMLDivElement>();
  const refs = useMemo(
    () =>
      Array.from({ length: links.length }).map(() =>
        createRef<HTMLHeadingElement>()
      ),
    [links.length]
  );
  useEffect(() => {
    setAnchors(links.map(link => link.id));
  }, []);
  const currentMenu = refs[currentAnchorIndex]?.current as HTMLElement;
  useEffect(() => {
    if (currentMenu) {
      const parentRect = (menuParentRef.current as HTMLElement)?.getBoundingClientRect();
      const rect = currentMenu.getBoundingClientRect();
      const css: CSSProperties = {};
      css.width = `${rect.width}px`;
      cssWithPrefix(
        css,
        "transform",
        `translate3d(${rect.left - parentRect?.left}px, 35px, 0)`
      );
      setCurrentStyle(css);
    }
  }, [currentAnchorIndex, currentMenu]);

  return (
    <AnchorProvider>
      <menu className={styles.anchorProvider}>
        <div className={`${styles.interviewBackground} pc-only`}>
          <div ref={menuParentRef} className={styles.interviewList}>
            <ul>
              {links.map((link, i) => (
                <li key={`anchor-headers-${i}`}>
                  <Link to={`#${link.id}`}>
                    <h3 ref={refs[i]}>{link.label}</h3>
                  </Link>
                </li>
              ))}
            </ul>
            {currentMenu && (
              <span className={styles.indicator} style={currentStyle} />
            )}
          </div>
        </div>
        <SpHorizontalMenu
          className={spContainerClassName}
          index={currentAnchorIndex}
        >
          {links.map((link, i) => (
            <li key={`anchor-headers-sp-${i}`} className={styles.anchorSpItem}>
              <Link to={`#${link.id}`}>
                <h3>{link.label}</h3>
              </Link>
            </li>
          ))}
        </SpHorizontalMenu>
      </menu>
    </AnchorProvider>
  );
};
