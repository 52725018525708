import React, { FunctionComponent } from "react";
import * as style from "./style.module.scss";
import scrollWhite from "../../images/vision/scrollwhite.png";
import scrollBlack from "../../images/vision/scrollblack.png";

type StateProps = {
  type: scrollImageColorTypes;
};

type ownProps = {
  className?: string;
};

type Props = StateProps & ownProps;

export enum scrollImageColorTypes {
  WHITE = "white",
  BLACK = "black",
}

const Scroll: FunctionComponent<Props> = ({ type, className = "" }) => {
  const scrollDown = () => {
    window.scrollTo({ top: window.innerHeight, behavior: "smooth" });
  };

  switch (type) {
    case scrollImageColorTypes.WHITE: {
      return (
        <div
          className={`${style.scrollWhite} ${className}`}
          onClick={() => {
            scrollDown();
          }}
        >
          <img src={scrollWhite} />
        </div>
      );
    }
    case scrollImageColorTypes.BLACK: {
      return (
        <div
          className={`${style.scrollBlack} ${className}`}
          onClick={() => {
            scrollDown();
          }}
        >
          <img src={scrollBlack} />
        </div>
      );
    }
  }
};

export default Scroll;
