// extracted by mini-css-extract-plugin
export var cover = "v_dS";
export var aboutTitle = "v_gB";
export var mid = "v_j6";
export var joblistTitle = "v_j7";
export var joblist = "v_j8";
export var joblistContainer = "v_j9";
export var table = "v_bS";
export var tableLast = "v_kb";
export var tableLeft = "v_kc";
export var tableRight = "v_kd";
export var idealTitle = "v_jc";
export var idealGray = "v_jd";
export var idealContainer = "v_jf";
export var idealText = "v_j4";
export var idealImg = "v_jg";
export var idealWhite = "v_jh";
export var idealWhiteContainer = "v_jj";
export var flow = "v_jl";
export var flowContainer = "v_jm";
export var flowBoxOne = "v_jn";
export var triangle = "v_fQ";
export var innerBox = "v_kf";
export var flowImg = "v_kg";
export var flowBoxTwo = "v_kh";
export var access = "v_kj";
export var accessContaniner = "v_kk";
export var accessContaninerLeft = "v_kl";
export var accessContaninerRight = "v_km";
export var information = "v_jp";
export var informationContainer = "v_kn";
export var informationContainerLeft = "v_kp";
export var informationContainerRight = "v_kq";
export var faq = "v_gV";
export var faqTab = "v_gW";
export var acdBackground = "v_kr";
export var acd = "v_g3";
export var question = "v_ks";
export var answer = "v_g4";
export var fadein = "v_g5";
export var button = "v_f";
export var viewAll = "v_gy";